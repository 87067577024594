//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: Object
        }
    },

    data() {
        return {
            is_extended: false
        };
    }
};
