//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FaqQuestionElement from "../components/FaqQuestionElement.vue";
export default {
    components: { FaqQuestionElement },

    data() {
        return {
            questions_and_answers: []
        };
    },

    methods: {
        onBackButtonClick() {
            if (!this.$root.$children[0].redirectUserToCurrentApplicationStepIfNeeded(true)) {
                this.$router.push("/");
            }
        }
    },

    mounted() {
        this.questions_and_answers = this.$store.getters["faq/getAllQuestions"];
    },

    metaInfo: {
        title: "Najczęściej zadawane pytania"
    }
};
